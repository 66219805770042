var GLOBAL = window;

$(function () {
  'use strict';

  var defaultTitle = 'Maxim V';

  GLOBAL.getAwstComponents = function (json) {
    var result = {};

    // Страницы
    result.InfoPage = Vue.extend({
      data: function () {
        var data = json[this.$route.name];
        document.title = data.seotitle || data.title || defaultTitle;
        return {
          content: data.content
        }
      },
      template: '#vuetpl-awst-info.page',
      ready: function () {
        var $scrollWindow = $(this.$el),
            $scrollbar = $scrollWindow.find('.js-scrollbar');//.mCustomScrollbar();

        if ( IS_DESKTOP ) {
          $scrollbar.mCustomScrollbar();
        }
      }
    });

    return result;
  };

});
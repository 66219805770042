var GLOBAL = window;
var $BODY = $('body');
var preloadSources = GLOBAL.preloadSources;

function initCommonScripts() {
  'use strict';

  $(document.body)
    .on('overlayLoaderShow', function(e, $node) {
      $node.find('.autofocus-inp').focus();
    });
}

$(function() {
  initCommonScripts();

  // document.ontouchmove = function(e) {
  //   if ( e.touches.length == 1 ) e.preventDefault();
  // }

  // document.ontouchmove = function(e) {
  //   e.preventDefault();
  // }

  $BODY.on('touchmove', function (e) {
    if (!$('.js-scrollbar').has($(e.target)).length) e.preventDefault();

    var $credoSlide = $('.credo-slide.js-scrollbar');
    if ( $credoSlide.length && $credoSlide.height() > $credoSlide.find('> .text-container').height() ) {
      e.preventDefault();
    }
  });

  var scrolling = false;

  $BODY.on('touchstart', '.js-scrollbar', function(e) {
    var $credoSlide = $('.credo-slide.js-scrollbar');
    if ( $credoSlide.length && $credoSlide.height() > $credoSlide.find('> .text-container').height() ) {
      return;
    }
    if (!scrolling) {
      scrolling = true;
      if (e.currentTarget.scrollTop === 0) {
        e.currentTarget.scrollTop = 1;
      } else if (e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.offsetHeight) {
        e.currentTarget.scrollTop -= 1;
      }
      scrolling = false;
    }
  });

  // $('body').on('touchmove','.js-scrollbar, .js-slider-item',function(e) {
  //   e.stopPropagation();
  // });

  // OverlayLoader init
  $(document).on('click.overlay', '.js-wait-for-bg', function(e) {
    e.preventDefault();
    $.overlayLoader(true, $($(this).attr('href')));
  });

  var $structure = $('#structure'),
      $overlay = $('.js-intro-overlay'),
      $skipOverlay = $('.js-skip-intro'),
      introTimeouts = [];

  // Подгружаем необходимые фоны
  $preloadBlock = $('<div class="preload-block"></div>');
  for ( var i = 0; i < preloadSources.length; i++ ) {
    $preloadBlock.append( $('<img />').attr('src', preloadSources[i]) );
  }
  $structure.append( $preloadBlock );

  $structure.imagesLoaded({ background: '.intro-bg' }).done(function() {
    // Запускаем движение текста, показываем логотипы, меняем фон
    // $('.main-menu > ul > li').addClass('stop-transition');
    // $('.main-menu > ul > li').removeClass('stop-transition');
    if ($(document).data('skip-intro')) return;
    introTimeouts.push(setTimeout(function() {
      $('.main-menu > ul > li').addClass('invisible');
      $structure.addClass('fade-in');
      introTimeouts.push(setTimeout(function() {
        $overlay.addClass('active');
        introTimeouts.push(setTimeout(function() {
          $('.js-london-logo, .js-intro-text, .js-mobile-intro-text').addClass('active');
          introTimeouts.push(setTimeout(function() {
            $('.js-footer').addClass('active');
            introTimeouts.push(setTimeout(function() {
              // $overlay.addClass('fade-out');
              $overlay.addClass('fade-out').fadeOut(1000);
              $('.js-intro-bg').addClass('fade-out');
              // $('.js-main-bg').addClass('fade-in');
              $('.js-main-logo').addClass('active');
              $('.js-artvivid-logo').addClass('fade-in');
              $('.js-application-block').fadeIn('slow');
              $skipOverlay.hide();
              $('.main-menu > ul > li').removeClass('invisible');
              setTimeout(function() {
                $('.main-menu > ul').addClass('active');
                $(document).trigger('intro-ready');
              }, 3500);
            }, 8000));
          }, 3000));
        }, 1000));
      }, 1000));
    }, 200));
  });

  var overlaySkip = function() {
    introTimeouts.forEach(function(timer) {
      clearTimeout(timer);
    });

    $structure.addClass('fade-in');
    $('.js-intro-bg').addClass('fade-out');
    // $('.js-main-bg').addClass('fade-in');
    $('.js-main-logo').addClass('active');
    $('.js-artvivid-logo').addClass('fade-in');
    $('.js-application-block').fadeIn('slow');
    $('.js-footer').addClass('active fast-animation');
    $('.js-intro-text, .js-mobile-intro-text, .js-london-logo').addClass('active fast-animation');
    // $overlay.addClass('fade-out');
    $overlay.addClass('fade-out').fadeOut(1000);
    $skipOverlay.hide();
    // $('.main-menu > ul > li').removeClass('invisible').addClass('stop-transition');
    setTimeout(function() {
      $('.main-menu > ul > li').removeClass('invisible');
    }, 50)
    $('.main-menu > ul').addClass('active');
    $(document).trigger('intro-ready');
  }
  GLOBAL.overlaySkip = overlaySkip;

  $skipOverlay.click(function() {
    // Пропуск заставки
    overlaySkip();
  });

  // Работа с историей (проставление :visited ссылкам)
  // var saveVisited = function(href) {
  //   var visitedLinks = JSON.parse(localStorage.getItem('visited_links')) || [];
  //   if ( visitedLinks.indexOf( href ) == -1 ) {
  //     visitedLinks.push( href );
  //   }

  //   localStorage.setItem('visited_links', JSON.stringify(visitedLinks));
  // }

  // var setVisited = function() {
  //   var visitedLinks = JSON.parse(localStorage.getItem('visited_links')) || [];
  //   for ( var i = 0; i < visitedLinks.length; i++ ) {
  //     $('a[href="' + visitedLinks[i] + '"]').addClass('visited');
  //   }
  // }
  // GLOBAL.setVisited = setVisited; // Это будем вызывать при инициализации компонента меню

  // $BODY.on('click', 'a[href]', function() {
  //   var self = $(this);
  //   self.addClass('visited');
  //   saveVisited( self.attr('href') );
  // });

  // setVisited();

  // Проверка девайса на тач
  function isTouchDevice() {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      return false;
    }
  }
  GLOBAL.isTouchDevice = isTouchDevice();
});
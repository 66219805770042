var GLOBAL = window;

$(function() { 'use strict';
  Vue.use(VueRouter);

  // $.ajaxSetup({"error":function(XMLHttpRequest,textStatus, errorThrown) {
  //     alert(textStatus);
  //     alert(errorThrown);
  //     alert(XMLHttpRequest.responseText);
  // }});

  var menuLoaded = false;

  $.getJSON('data.json', function(json) {
    var defaultTitle = 'Maxim V';
    var Home = Vue.extend({
      data: function () {
        var data = json.showcase;
        document.title = data.seotitle || data.title || defaultTitle;
        return {
          content: data.content
        }
      },
      template: '#vuetpl-home',
      ready: function() {
        var $mainMenu = $('.main-menu > ul');
        $mainMenu.imagesLoaded().done(function() {
          function fixMenu() {
            var $menuSlide = $('.menu-slide'),
                windowWidth = $(window).width(),
                windowHeight = $(window).height();

            // Правим высоту блока с меню на айпадах с учетом вкладок браузера
            $menuSlide.removeAttr('style');
            if ( windowWidth <= 1024 ) {
              if ( windowWidth > 768 ) {
                $menuSlide.attr('style', 'height: ' + (windowHeight - 205) + 'px !important');
              }
              if ( windowWidth == 768 ) {
                $menuSlide.attr('style', 'height: ' + (windowHeight - 200) + 'px !important');
              }
            }

            // Поправляем aspect-ratio по картинкам в меню
            $mainMenu.find('> li').each(function() {
              var $self = $(this),
                  $img = $self.find('img'),
                  $app = $('#app');
              if ( !menuLoaded ) $app.show();
              $self.removeAttr('style');
              // При переворотах экрана в этот момент браузер почему-то еще не успевает всё пересчитать, поэтому выкручиваемся как можем
              if ( window.matchMedia('(orientation: landscape)').matches ) {
                $self.css('height', Math.floor((($menuSlide.width() / 3) * $img.attr('height')) / $img.attr('width')));
              }
              if ( window.matchMedia('(orientation: portrait)').matches ) {
                $self.css('width', Math.floor((($menuSlide.height() / 3) * $img.attr('width')) / $img.attr('height')));
              }
              if ( !menuLoaded ) $app.hide();
            });
            $mainMenu.parent().removeAttr('style');
            if ( window.matchMedia('(orientation: landscape)').matches ) {
              // Делаем ограничение чуть меньше, чем высота двух картинок
              $mainMenu.parent().css('max-height', $mainMenu.find('.first-folder').height() * 2 * .9);
            }
          }

          fixMenu();

          $(window).resize(function() {
            fixMenu();
          });

          if ( menuLoaded ) {
            setTimeout(function() {
              $mainMenu.addClass('active stop-transition');
            }, 800);
          }

          menuLoaded = true;
        });

        // На главной показываем лого ArtVivid
        $('.js-artvivid-logo').removeClass('fade-out').addClass('fade-in');

        // GLOBAL.setVisited();
      }
    });

    var Credo = Vue.extend({
      data: function () {
        var data = json.credo;
        document.title = data.seotitle || data.title || defaultTitle;
        return {
          content: data.content
        }
      },
      template: '#vuetpl-credo',
      ready: function() {
        var $scrollWindow = $(this.$el),
            $scrollbar = $scrollWindow.find('.js-scrollbar');//.mCustomScrollbar();

        if ( IS_DESKTOP ) {
          $scrollbar.mCustomScrollbar();
        }

        var $overlay = $(this.$el).find('.overlay');
        setTimeout(function() {
          $overlay.fadeTo(6000, .25);
        }, 500);
      }
    });

    var History = Vue.extend({
      data: function () {
        var data = json.history;
        document.title = data.seotitle || data.title || defaultTitle;
        return {
          content: data.content
        }
      },
      template: '#vuetpl-history',
      ready: function() {
        var $scrollWindow = $(this.$el),
            $scrollbar = $scrollWindow.find('.js-scrollbar');//.mCustomScrollbar();

        if ( IS_DESKTOP ) {
          $scrollbar.mCustomScrollbar();
        }

        // Colorbox
        /*$('.colorbox').colorbox({
          scrolling: false,
          maxWidth: '90%',
          maxHeight: '90%',
          opacity: .85
        });*/

        var $overlay = $(this.$el).find('.overlay');
        setTimeout(function() {
          $overlay.fadeTo(6000, .25);
        }, 500);
      }
    });

    var CollectionsList = Vue.extend({
      data: function () {
        var data = json.collections;
        document.title = data.seotitle || data.title || defaultTitle;
        return {
          collections: data.items
        }
      },
      template: '#vuetpl-collections-list',
      ready: function() {
        var $overlay = $(this.$el).find('.overlay');
        setTimeout(function() {
          $overlay.fadeTo(3000, .25);
        }, 500);

        // var $scrollWindow = $(this.$el),
        //     $scrollbar = $scrollWindow.find('.js-scrollbar');//.mCustomScrollbar();

        // if ( IS_DESKTOP ) {
        //   $scrollbar.mCustomScrollbar();
        // }
      }
    });

    var Collection = Vue.extend({
      data: function () {
        var data = json.collections;
        document.title = data.seotitle || data.title || defaultTitle;
        var projectsList = data.items,
            fullGallery = data.gallery,
            projectID = this.$route.params.collection,
            project = projectsList[projectID],
            projectName = project.title,
            projectSlidesIDs = project.gallery,
            gallerySlides = {};

        for ( var slide in fullGallery ) {
          // Оставляем в галерее только слайды, относящиеся к текущей коллекции
          if ( projectSlidesIDs.indexOf( fullGallery[slide].id ) >= 0 ) {
            gallerySlides[slide] = fullGallery[slide];
          }
        };

        return {
          // gallery: data.gallery
          gallery: gallerySlides,
          title: projectName
        }
      },
      template: '#vuetpl-collection',
      ready: function() {
        var $slider = $(this.$el).find('.js-works-slider');

        // Инициализируем слайдер
        if ( IS_DESKTOP ) {
          $('.js-scrollbar').removeClass('js-scrollbar');

          var tos = $slider.tosrus({
            buttons: false,
            keys: {
              prev: true,
              next: true
            }
          });

          var activeIndex = 0;

          tos.bind('sliding.tos', function( event, index ) {
            $slider.find('.tos-slide').eq( index ).addClass('active').siblings().removeClass('active');
            // При перемотке слайдера скрываем текст, если он был открыт для прошлого слайда
            var $prevSlide = $slider.find('.tos-slide').eq( activeIndex ),
                $textLink = $prevSlide.find('.js-text-link'),
                $text = $prevSlide.find('.js-media-text'),
                $img = $prevSlide.find('img');
            $text.stop(true, true).hide();
            $textLink.removeClass('invisible');
            $img.fadeTo(500, 1);
            activeIndex = index;
          });

          $slider.find('.tos-slide').first().addClass('active');

          // Перематываем слайдер колесиком мышки
          $slider.on('mousewheel', function(event) {
            if ( $slider.hasClass('busy') ) return;

            $slider.addClass('busy');

            if ( event.deltaY < 0 ) {
              tos.trigger('next');
            } else if ( event.deltaY > 0 ) {
              tos.trigger('prev');
            }

            //$('.zoomImg').stop(true, true).fadeTo(0, 0);

            setTimeout(function() {
              $slider.removeClass('busy');
            }, 500);
          });

          /*$('.js-slider-item > img').each(function() {
            $(this).wrap( $('<span class="zoomer"></span>') ).parent().zoom({
              magnify: 1.5,
              onZoomIn: function() {
                clearTimeout( autoScrollTimeout );
                clearInterval( autoScroll );
              }
            });
          });*/
        }

        var autoScroll;
        var autoScrollTimeout = setTimeout(function() {
          // Через несколько секунд после показа слайда с портфолио перематываем на следующий слайд,
          // чтобы показать, что есть куда мотать
          var nextSlideIndex = 0;
          autoScroll = setInterval(function() {
            if ( IS_MOBILE ) {
              nextSlideIndex += 1;
              var nextSlide = $slider.find('.js-slider-item:eq(' + nextSlideIndex + ')');
              if ( nextSlide.length ) {
                $slider.closest('.js-scrollbar').scrollTo($slider.find('.js-slider-item:eq(' + nextSlideIndex + ')'), 1500);
              } else {
                clearInterval( autoScroll );
              }
            } else if ( IS_DESKTOP ) {
              tos.trigger('next');
            }
          }, 4000);
        }, 0);

        // Если начали мотать вручную, отменяем автоперемотку к следующему слайду
        if ( IS_MOBILE ) {
          $('.js-scrollbar').on('touchstart touchmove', function() {
            clearTimeout( autoScrollTimeout );
            clearInterval( autoScroll );
          });
        } else if ( IS_DESKTOP ) {
          $('body').on('mousewheel', function() {
            clearTimeout( autoScrollTimeout );
            clearInterval( autoScroll );
          });
        }

        if ( IS_MOBILE ) {
          var $scrollWindow = $(this.$el);
          // var $scrollbar = $scrollWindow.find('.js-scrollbar').scrollbar({disableBodyScroll: true});
          var $scrollbar = $scrollWindow.find('.js-scrollbar');//.mCustomScrollbar();

          // Показываем кнопки с текстом и прочие контролы для первого слайда
          $slider.find('.js-slider-item').first().addClass('active');
          var activeIndex = 0;

          $scrollbar.scroll(function() {
            var scrollTop = $scrollbar.scrollTop();

            if ( scrollTop <= 1 ) return;

            // Скрываем открытый текст при скролле
            var $currentSlide = $slider.find('.js-slider-item').eq( activeIndex ),
                $textLink = $currentSlide.find('.js-text-link'),
                $text = $currentSlide.find('.js-media-text'),
                $img = $currentSlide.find('img');
            $text.stop(true, true).fadeOut();
            clearTimeout( $text.data('hide-timeout') );
            $textLink.removeClass('invisible');
            $img.fadeTo(0, 1);

            // Показываем кнопки от текста для первого слайда, для которого видны не менее 70% его высоты
            $slider.find('.js-slider-item').each(function(index) {
              var $slide = $(this);
              if ( $slide.position().top >= -$slide.height() * .7 ) {
                $slide.addClass('active').siblings().removeClass('active');
                return false;
              }
              activeIndex = index;
            });
          });

          // Пинч-зум для мобильных
          // if ( $slider.find('.js-slider-item').length == 1 ) {
            $slider.find('.js-slider-item img').each(function() {
              $(this).panzoom({
                minScale: 1,
                maxScale: 3,
                panOnlyWhenZoomed: true,
                contain: 'invert',
                increment: 1,
                // linearZoom: 1,
                onZoom: function() {
                  // Если начали зумить - отменяем автоперемотку
                  clearTimeout( autoScrollTimeout );
                  clearInterval( autoScroll );
                }
              });
            });
          // } else { // Если передавать разные значения panOnlyWhenZoomed и disablePan -
          //          // нихуя не работает как надо, канает уже само наличие свойства
          //          // поэтому придется продублировать вызов вот так
          //   $slider.find('.js-slider-item img').each(function() {
          //     $(this).panzoom({
          //       minScale: 1,
          //       maxScale: 3,
          //       disablePan: true,
          //       increment: 1
          //     });
          //   });
          // }
        }

        // Открываем видео в галерее
        // $slider.find('.js-video-link').each(function() {
        //   var videoLink = $(this);
        //   var tosOverlay = videoLink.tosrus({
        //     buttons: false,
        //     keys: {
        //       close: true
        //     },
        //     drag: false
        //   });
        //   tosOverlay.bind('opening.tos', function(event) {
        //     if ($(event.target).find('video').length) {
        //       $(event.target).find('video')[0].play();
        //     }
        //     $('.tos-wrapper.tos-fixed').click(function() {
        //       tosOverlay.trigger('close');
        //     });
        //     $('.tos-wrapper.tos-fixed .tos-slide > *').click(function(e) {
        //       e.stopPropagation();
        //     });
        //     // Отменяем автоскролл при открытии видео
        //     clearTimeout( autoScrollTimeout );
        //     clearInterval( autoScroll );
        //   });
        //   tosOverlay.bind('close.tos', function(event) {
        //     if ($(event.target).find('video').length) {
        //       $(event.target).find('video')[0].pause();
        //     } else if ($(event.target).find('iframe').length) {
        //       var iframe = $($(event.target).find('iframe')[0]).clone(true, true);
        //       $(event.target).find('.video-block iframe').remove();
        //       $(event.target).find('.video-block').append(iframe);
        //     }
        //   });
        // });

        // Показ текста поверх слайдера
        $slider.find('.js-text-link').each(function() {
          var $textLink = $(this),
              $currentSlide = $textLink.closest('.js-slider-item'),
              $controls = $currentSlide.find('.js-controls'),
              $text = $currentSlide.find('.js-media-text'),
              $img = $currentSlide.find('img');
          $textLink.on('mouseenter touchstart', function() {
            $text.stop(true, true).fadeIn();
            $textLink.addClass('invisible');
            clearTimeout( $text.data('hide-timeout') );
            // В проектах фейдим картинку при просмотре текста
            if (IS_MOBILE) {
              $img.fadeTo(500, .25);
            }
            // Отменяем автоскролл при показе текста
            clearTimeout( autoScrollTimeout );
            clearInterval( autoScroll );
          });
          $text.on('mouseleave touchend', function() {
            $text.data('hide-timeout', setTimeout(function() {
              $text.stop(true, true).fadeOut();
              $textLink.removeClass('invisible');
              if (IS_MOBILE) {
                $img.fadeTo(500, 1);
              }
            }, 350));
          });
        });
      }
    });

    var ProjectsList = Vue.extend({
      data: function () {
        var data = json.projects;
        document.title = data.seotitle || data.title || defaultTitle;
        return {
          projects: data.items
        }
      },
      template: '#vuetpl-projects-list',
      ready: function() {
        var $overlay = $(this.$el).find('.overlay');
        setTimeout(function() {
          $overlay.fadeTo(3000, .25);
        }, 500);

        // var $scrollWindow = $(this.$el),
        //     $scrollbar = $scrollWindow.find('.js-scrollbar');//.mCustomScrollbar();

        // if ( IS_DESKTOP ) {
        //   $scrollbar.mCustomScrollbar();
        // }
      }
    });

    var Project = Vue.extend({
      data: function () {
        var data = json.projects;
        document.title = data.seotitle || data.title || defaultTitle;
        var projectsList = data.items,
            fullGallery = data.gallery,
            projectID = this.$route.params.project,
            project = projectsList[projectID],
            projectName = project.title,
            projectSlidesIDs = project.gallery,
            gallerySlides = {};

        for ( var slide in fullGallery ) {
          // Оставляем в галерее только слайды, относящиеся к текущему проекту
          if ( projectSlidesIDs.indexOf( fullGallery[slide].id ) >= 0 ) {
            gallerySlides[slide] = fullGallery[slide];
          }
        };

        return {
          // gallery: data.gallery
          gallery: gallerySlides,
          title: projectName
        }
      },
      template: '#vuetpl-project',
      ready: function() {
        var $slider = $(this.$el).find('.js-works-slider');

        // Инициализируем слайдер
        if ( IS_DESKTOP ) {
          $('.js-scrollbar').removeClass('js-scrollbar');

          var tos = $slider.tosrus({
            buttons: false,
            keys: {
              prev: true,
              next: true
            }
          });

          var activeIndex = 0;

          tos.bind('sliding.tos', function( event, index ) {
            $slider.find('.tos-slide').eq( index ).addClass('active').siblings().removeClass('active');
            // При перемотке слайдера скрываем текст, если он был открыт для прошлого слайда
            var $prevSlide = $slider.find('.tos-slide').eq( activeIndex ),
                $textLink = $prevSlide.find('.js-text-link'),
                $text = $prevSlide.find('.js-media-text'),
                $img = $prevSlide.find('img');
            $text.stop(true, true).hide();
            $textLink.removeClass('invisible');
            $img.fadeTo(500, 1);
            activeIndex = index;
          });

          $slider.find('.tos-slide').first().addClass('active');

          // Перематываем слайдер колесиком мышки
          $slider.on('mousewheel', function(event) {
            if ( $slider.hasClass('busy') ) return;

            $slider.addClass('busy');

            if ( event.deltaY < 0 ) {
              tos.trigger('next');
            } else if ( event.deltaY > 0 ) {
              tos.trigger('prev');
            }

            //$('.zoomImg').stop(true, true).fadeTo(0, 0);

            setTimeout(function() {
              $slider.removeClass('busy');
            }, 500);
          });

          /*$('.js-slider-item > img').each(function() {
            $(this).wrap( $('<span class="zoomer"></span>') ).parent().zoom({
              magnify: 1.5,
              onZoomIn: function() {
                clearTimeout( autoScrollTimeout );
                clearInterval( autoScroll );
              }
            });
          });*/
        }

        var autoScroll;
        var autoScrollTimeout = setTimeout(function() {
          // Через несколько секунд после показа слайда с портфолио перематываем на следующий слайд,
          // чтобы показать, что есть куда мотать
          var nextSlideIndex = 0;
          autoScroll = setInterval(function() {
            if ( IS_MOBILE ) {
              nextSlideIndex += 1;
              var nextSlide = $slider.find('.js-slider-item:eq(' + nextSlideIndex + ')');
              if ( nextSlide.length ) {
                $slider.closest('.js-scrollbar').scrollTo($slider.find('.js-slider-item:eq(' + nextSlideIndex + ')'), 1500);
              } else {
                clearInterval( autoScroll );
              }
            } else if ( IS_DESKTOP ) {
              tos.trigger('next');
            }
          }, 4000);
        }, 0);

        // Если начали мотать вручную, отменяем автоперемотку к следующему слайду
        if ( IS_MOBILE ) {
          $('.js-scrollbar').on('touchstart touchmove', function() {
            clearTimeout( autoScrollTimeout );
            clearInterval( autoScroll );
          });
        } else if ( IS_DESKTOP ) {
          $('body').on('mousewheel', function() {
            clearTimeout( autoScrollTimeout );
            clearInterval( autoScroll );
          });
        }

        if ( IS_MOBILE ) {
          var $scrollWindow = $(this.$el);
          // var $scrollbar = $scrollWindow.find('.js-scrollbar').scrollbar({disableBodyScroll: true});
          var $scrollbar = $scrollWindow.find('.js-scrollbar');//.mCustomScrollbar();

          // Показываем кнопки с текстом и прочие контролы для первого слайда
          $slider.find('.js-slider-item').first().addClass('active');
          var activeIndex = 0;

          $scrollbar.scroll(function() {
            // Скрываем открытый текст при скролле
            var scrollTop = $scrollbar.scrollTop();

            if ( scrollTop <= 1 ) return;

            var $currentSlide = $slider.find('.js-slider-item').eq( activeIndex ),
                $textLink = $currentSlide.find('.js-text-link'),
                $text = $currentSlide.find('.js-media-text'),
                $img = $currentSlide.find('img');
            $text.stop(true, true).fadeOut();
            clearTimeout( $text.data('hide-timeout') );
            $textLink.removeClass('invisible');
            $img.fadeTo(0, 1);

            // Показываем кнопки от текста для первого слайда, для которого видны не менее 70% его высоты
            $slider.find('.js-slider-item').each(function(index) {
              var $slide = $(this);
              if ( $slide.position().top >= -$slide.height() * .7 ) {
                $slide.addClass('active').siblings().removeClass('active');
                return false;
              }
              activeIndex = index;
            });
          });

          // Пинч-зум для мобильных
          // if ( $slider.find('.js-slider-item').length == 1 ) {
            $slider.find('.js-slider-item img').each(function() {
              $(this).panzoom({
                minScale: 1,
                maxScale: 3,
                panOnlyWhenZoomed: true,
                contain: 'invert',
                increment: 1,
                // linearZoom: 1,
                onZoom: function() {
                  // Если начали зумить - отменяем автоперемотку
                  clearTimeout( autoScrollTimeout );
                  clearInterval( autoScroll );
                }
              });
            });
          // } else { // Если передавать разные значения panOnlyWhenZoomed и disablePan -
          //          // нихуя не работает как надо, канает уже само наличие свойства
          //          // поэтому придется продублировать вызов вот так
          //   $slider.find('.js-slider-item img').each(function() {
          //     $(this).panzoom({
          //       minScale: 1,
          //       maxScale: 3,
          //       disablePan: true,
          //       increment: 1
          //     });
          //   });
          // }
        }

        // Открываем видео в галерее
        // $slider.find('.js-video-link').each(function() {
        //   var videoLink = $(this);
        //   var tosOverlay = videoLink.tosrus({
        //     buttons: false,
        //     keys: {
        //       close: true
        //     },
        //     drag: false
        //   });
        //   tosOverlay.bind('opening.tos', function(event) {
        //     if ($(event.target).find('video').length) {
        //       $(event.target).find('video')[0].play();
        //     }
        //     $('.tos-wrapper.tos-fixed').click(function() {
        //       tosOverlay.trigger('close');
        //     });
        //     $('.tos-wrapper.tos-fixed .tos-slide > *').click(function(e) {
        //       e.stopPropagation();
        //     });
        //     // Отменяем автоскролл при открытии видео
        //     clearTimeout( autoScrollTimeout );
        //     clearInterval( autoScroll );
        //   });
        //   tosOverlay.bind('close.tos', function(event) {
        //     if ($(event.target).find('video').length) {
        //       $(event.target).find('video')[0].pause();
        //     } else if ($(event.target).find('iframe').length) {
        //       var iframe = $($(event.target).find('iframe')[0]).clone(true, true);
        //       $(event.target).find('.video-block iframe').remove();
        //       $(event.target).find('.video-block').append(iframe);
        //     }
        //   });
        // });

        // Показ текста поверх слайдера
        $slider.find('.js-text-link').each(function() {
          var $textLink = $(this),
              $currentSlide = $textLink.closest('.js-slider-item'),
              $controls = $currentSlide.find('.js-controls'),
              $text = $currentSlide.find('.js-media-text'),
              $img = $currentSlide.find('img');
          $textLink.on('mouseenter touchstart', function() {
            $text.stop(true, true).fadeIn();
            $textLink.addClass('invisible');
            clearTimeout( $text.data('hide-timeout') );
            // В проектах фейдим картинку при просмотре текста
            if (IS_MOBILE) {
              $img.fadeTo(500, .25);
            }
            // Отменяем автоскролл при показе текста
            clearTimeout( autoScrollTimeout );
            clearInterval( autoScroll );
          });
          $text.on('mouseleave touchend', function() {
            $text.data('hide-timeout', setTimeout(function() {
              $text.stop(true, true).fadeOut();
              $textLink.removeClass('invisible');
              if (IS_MOBILE) {
                $img.fadeTo(500, 1);
              }
            }, 350));
          });
        });
      }
    });

    var Contact = Vue.extend({
      data: function () {
        var data = json.contact;
        document.title = data.seotitle || data.title || defaultTitle;
        return {}
      },
      template: '#vuetpl-contact',
      ready: function() {
        var $slide = $(this.$el).find('.slide');
        if ( IS_DESKTOP ) {
          $slide.find('.inp:first').focus();
        } else {
          // Убираем на мобильных отправку формы по enter
          $('input, textarea').keypress(function(e){
            if ( e.which == 13 ) return false;
          });
        }
        var $overlay = $(this.$el).find('.overlay');
        setTimeout(function() {
          $overlay.fadeTo(6000, .25);
        }, 500);
        // Отправка формы
        var form = $('.contact-form', $slide);
        form.on('submit', function (e) {
          e.preventDefault();
          var data = form.serializeArray();
          var action = document.location.pathname + '?form-return=' + encodeURI(document.location.pathname + '?vuetpl=contact');
          $.post(action, data, function (result) {
            var newform = $(result).find('.contact-form');
            form.html(newform.html());
          }, 'html');
        });
      }
    });

    // var Partners = Vue.extend({
    //   data: function () {
    //     var data = json.partners;
    //     document.title = data.seotitle || data.title || defaultTitle;
    //     return {
    //       partners: data.gallery
    //     }
    //   },
    //   template: '#vuetpl-partners',
    //   ready: function() {
    //     var $scrollWindow = $(this.$el),
    //         $scrollbar = $scrollWindow.find('.js-scrollbar').mCustomScrollbar();
    //   }
    // });

    var Stores = Vue.extend({
      data: function () {
        var data = json.stores;
        document.title = data.seotitle || data.title || defaultTitle;
        return {
          content: data.content
        }
      },
      template: '#vuetpl-stores',
      ready: function() {
        var $overlay = $(this.$el).find('.overlay');
        setTimeout(function() {
          $overlay.fadeTo(6000, .25);
        }, 500);
      }
    });

    var App = Vue.extend();

    var router = new VueRouter({
      hashbang: false,
      history: true
    });

    // Дефолтные маршруты
    var defaultRouterMap = {};
    var AwstComponents = GLOBAL.getAwstComponents(json);
    $.each(json, function (key, value) {
      // Страницы
      if (value.type == 'info.page') {
        defaultRouterMap[value.url] = {
          name: key,
          component: AwstComponents.InfoPage
        };
      }
    });

    // Маршруты со специфическими компонентами
    var routerMap = $.extend(defaultRouterMap, {
      '/': {
        name: 'root',
        component: Home
      },
      '/home': {
        name: 'home',
        component: Home
      },
      '/credo': {
        name: 'credo',
        component: Credo
      },
      '/history': {
        name: 'history',
        component: History
      },
      // '/works': {
      //   name: 'works',
      //   component: Works
      // },
      '/collections': {
        name: 'collectionsList',
        component: CollectionsList
      },
      '/collections/:collection': {
        name: 'collection',
        component: Collection
      },
      '/projects': {
        name: 'projectsList',
        component: ProjectsList
      },
      '/projects/:project': {
        name: 'project',
        component: Project
      },
      '/contact': {
        name: 'contact',
        component: Contact
      },
      // '/partners': {
      //   name: 'partners',
      //   component: Partners
      // }
      '/stores': {
        name: 'stores',
        component: Stores
      }
    });

    router.map(routerMap);

    router.redirect({
      '*': '/home'
    });

    router.beforeEach(function (transition) {
      // Пропускаем интро, если зашли на сайт не с главного слайда
      if (document.location.pathname != '/') {
        $(document).data('skip-intro', true);
        GLOBAL.overlaySkip();
        menuLoaded = true;
      }
      // Переходим на /home, если интро было показано на главной странице
      if (transition.to.name == 'root') {
        $(document).one('intro-ready', function () {
          router.replace('/home');
        });
      } else {
        if (transition.from.name != 'root') {
          $('.js-intro-bg').addClass('fast-animation');
        }
      }
      transition.next()
      $('.js-footer').removeClass('fast-animation');
    });

    router.afterEach(function (transition) {
      // Пинаем фантом, типа "Страница готова"
      if (typeof window.callPhantom === 'function') {
        window.callPhantom({
          transition: transition
        });
      }
      // Показываем логотип ArtVivid только на главной
      if (transition.to.name != 'root' && transition.to.name != 'home') {
        $('.js-artvivid-logo').addClass('fade-out').removeClass('fade-in');
      }
    });

    router.start(App, '#app');
  });
});